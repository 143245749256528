<template>
  <div class="add-new-stock">
    <title-bar
      submitBtn
      submitBtnTitle="Update warehouse"
      title="Update warehouse"
      @submitPressed="submitPressed"
    />
    <stock-form v-if="stock" :stockObject="stock" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import StockForm from './components/StockForm.vue'
import router from '@/router'

export default {
  components: {
    TitleBar,
    StockForm
  },
  data() {
    return {
      stock: null,
      action: null,
    }
  },
  created() {
    this.loadStock()
  },
  methods: {
    loadStock() {
      const { id } = router.currentRoute.params
      this.$Warehouses.getResource({ id }).then(response => {
        this.stock = response.data
      })
    },
    submitPressed() {
      this.action = 'update'
    },
  }
}
</script>
