var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"stockForm",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"row",attrs:{"label":"Name","label-for":"name","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name","vid":"stock.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.stock.name),callback:function ($$v) {_vm.$set(_vm.stock, "name", $$v)},expression:"stock.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"City","label-for":"city","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"City","vid":"stock.address.city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","state":errors.length > 0 ? false : null},model:{value:(_vm.stock.address.city),callback:function ($$v) {_vm.$set(_vm.stock.address, "city", $$v)},expression:"stock.address.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Postcode","label-for":"postcode","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Postcode","vid":"stock.address.postalCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postcode","state":errors.length > 0 ? false : null},model:{value:(_vm.stock.address.postalCode),callback:function ($$v) {_vm.$set(_vm.stock.address, "postalCode", $$v)},expression:"stock.address.postalCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Street","label-for":"street","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Street","vid":"stock.address.streetAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"street","state":errors.length > 0 ? false : null},model:{value:(_vm.stock.address.streetAddress),callback:function ($$v) {_vm.$set(_vm.stock.address, "streetAddress", $$v)},expression:"stock.address.streetAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Street number","label-for":"street-number","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Street number","vid":"stock.address.streetNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"street-number","state":errors.length > 0 ? false : null},model:{value:(_vm.stock.address.streetNumber),callback:function ($$v) {_vm.$set(_vm.stock.address, "streetNumber", $$v)},expression:"stock.address.streetNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Phone","label-for":"phone","label-cols-md":"2","content-cols-lg":"4"}},[_c('b-form-input',{attrs:{"id":"phone"},model:{value:(_vm.stock.telephone),callback:function ($$v) {_vm.$set(_vm.stock, "telephone", $$v)},expression:"stock.telephone"}})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Email","label-for":"email","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"Email","vid":"stock.email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.stock.email),callback:function ($$v) {_vm.$set(_vm.stock, "email", $$v)},expression:"stock.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Description","label-for":"description","label-cols-md":"2","content-cols-lg":"4"}},[_c('b-form-textarea',{attrs:{"id":"description","name":"Description"},model:{value:(_vm.stock.description),callback:function ($$v) {_vm.$set(_vm.stock, "description", $$v)},expression:"stock.description"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }