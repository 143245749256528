<template>
  <validation-observer ref="stockForm" tag="form" @submit.prevent="onSubmit">
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="row"
            label="Name"
            label-for="name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Name"
              vid="stock.name"
            >
              <b-form-input
                v-model="stock.name"
                id="name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="City"
            label-for="city"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="City"
              vid="stock.address.city"
            >
              <b-form-input
                id="city"
                v-model="stock.address.city"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Postcode"
            label-for="postcode"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Postcode"
              vid="stock.address.postalCode"
            >
              <b-form-input
                id="postcode"
                v-model="stock.address.postalCode"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Street"
            label-for="street"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Street"
              vid="stock.address.streetAddress"
            >
              <b-form-input
                id="street"
                v-model="stock.address.streetAddress"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Street number"
            label-for="street-number"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Street number"
              vid="stock.address.streetNumber"
            >
              <b-form-input
                id="street-number"
                v-model="stock.address.streetNumber"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Phone"
            label-for="phone"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input id="phone" v-model="stock.telephone" />
          </b-form-group>

          <b-form-group
            class="row"
            label="Email"
            label-for="email"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required|email"
              name="Email"
              vid="stock.email"
            >
              <b-form-input
                id="email"
                type="email"
                v-model="stock.email"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Description"
            label-for="description"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-textarea
              id="description"
              name="Description"
              v-model="stock.description"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    stockObject: { Object, required: true },
    action: { type: String, default: null },
  },
  data() {
    return {
      stock: this.stockObject,
    }
  },
  watch: {
    action() {
      this.onSubmit()
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.stockForm.validate()
      if (isValid) {
        if (this.action === 'add') {
          this.$Warehouses
            .createResource({ body: this.stock })
            .then(response => {
              if (response.status === 201) {
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  'New stock created',
                  '',
                  'success',
                )
                this.$router.push(`/warehouses`)
              }
            })
            .catch(error => {
              if (error.response) {
                this.$emit('clearAction')
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  error.response.data['hydra:title'],
                  error.response.data['hydra:description'],
                  'danger',
                )
              }
            })
        }

        if (this.action === 'update') {
          this.$Warehouses
            .updateResource({ id: this.stock.id, body: this.stock })
            .then(response => {
              if (response.status === 200) {
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  'Customer updated',
                  '',
                  'success',
                )
                this.$router.push(`/warehouses`)
              }
            })
            .catch(error => {
              if (error.response) {
                this.$emit('clearAction')
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  error.response.data['hydra:title'],
                  error.response.data['hydra:description'],
                  'danger',
                )
              }
            })
        }
      } else {
        this.$emit('clearAction')
      }
    },
  },
}
</script>
